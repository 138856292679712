.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  min-height: 60vh;
  padding: 20px;
  text-align: center;
  background: #f5f6fa;
}

.title {
  margin-top: 20px;
  font-size: 14px;
  color: #C2c4cc
}